/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

/* -------------------------------- Packages -------------------------------- */
import * as React from "react";
import PropTypes from 'prop-types';
import 'lazysizes';
import { Link } from "gatsby";

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function SingleSlide({data}) {
    const { image,imageblur, title, desc, link}= data.content;

    return (
    <>        
      <div className="slide" id="carrouselSlide">
        <img className="image prlximg lazyload" id="carrouselImage" data-src={image} src={imageblur}  alt={title} />
        <div className="textContainer" id="carrouselTextContainer">
          <h2 className="prlx" >{title}</h2>
          <p className="prlx" >{desc}</p>
          <Link to={"/projects"+"?lang="+data.lang+"#"+link} className="readMoreBtn prlx">{data.readmore}</Link>
        </div>
      </div>
    </>
  );
}
SingleSlide.prototypes={
  data: PropTypes.objectOf({
    image: PropTypes.string,
    imageblur: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
};
export default SingleSlide;
/* ---------------------------- Styled Components --------------------------- */


/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */