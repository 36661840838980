/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

/* -------------------------------- Packages -------------------------------- */
import * as React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import  {getpageLang} from "../shared/extFunctions";
/* ------------------------------ Import pages ------------------------------ */
import SingleSlide from "./slide";
import carrouselContent from "../shared/json/landingPage.json";
/* ------------------------------ Local Images ------------------------------ */
import downArrow from "../images/downArrow.png";

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */
function Carrousel({data}) {
    const lang = getpageLang(data.lang);
    const content = lang==="fr"? carrouselContent.fr.carrousel: carrouselContent.en.carrousel;
    return (
        <>
            <Container id="carrouselContainer">
                <Slider {...settings}>
                    {content.Contents.map((slide, i)=>{
                        return (
                            <SingleSlide key={"slide"+i} data={{content: slide,readmore: content.readmore, lang: lang}} />
                        )
                    })}
                </Slider>
                <DownArrow className="downarrow" href="#aulc">
                    <img src={downArrow} alt="just an arrow man what do you want from me" />
                </DownArrow>
            </Container>
        </>
    );
}
export default Carrousel;
/* ---------------------------- Styled Components --------------------------- */
const Container = styled.div`
    @import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap");
    @import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
    /* @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100;8..144,200;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800;8..144,900;8..144,1000&display=swap'); */
    width: 100%;
    height: 100vh;
    /* background-color: red; */
    cursor: grab;
    .image {
        object-fit: cover;
        height: 100vh;
        width: 100%;
        z-index: 1;
        filter: brightness(0.6);
        object-position: center bottom;
    }
    .slick-dots {
        top: 94vh;

        li {
            button {
                &::before {
                    font-size: 25px;
                    color: #fff;
                    text-shadow: 3px 3px 2px #000;
                    content: "■";
                }
                .slick-active::before {
                    color: #ac9f67;
                }
            }
        }
    }
    .textContainer {
        margin-left: 6vw;
        position: absolute;
        top: 30vh;
        width: 60vw;
        color: #fff;
        font-family: "Roboto Condensed", sans-serif;
        h2 {
            font-size: 50px;
            margin-bottom: 5px;
        }
        p {
            /* text-indent: 5ch; */
            font-size: 25px;
            font-weight: 100;
            line-height: 23px;
            text-justify: inter-word;
        }
        .readMoreBtn {
            font-family: "Gothic A1", sans-serif;
            font-weight: 500;
            text-decoration: none;
            color: #000;
            background-color: #e1d8cc;
            padding: 10px 35px;
            box-shadow: 7px 4px 20px 0px #00000090;
            border-radius: 2px;
            transition: all 0.3s;

            &:hover {
                color: #e1d8cc;
                background-color: #000;
            }
        }
        @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
            width: 100vw;
            height: 80vh;
            top: 20vh;
            margin: 0;
            h2 {
                padding: 0 1vw;
            }
            p {
                padding: 0 2vw;
            }
        }
    }
`;
const DownArrow = styled.a`
    img {
        width: 3vw;
        min-width: 25px;
        top: 92vh;
        right: 1vw;
        position: absolute;
        transition: all 1s;
        &:hover {
            filter: brightness(50%) sepia(1) hue-rotate(-4deg) saturate(30.5%)
                brightness(140%);
        }
    }
`;
/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */
const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: false,
    lazyLoad: "progressive",
    speed: 700,
    swipe: true,
};
