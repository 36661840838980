/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

/* ---------------------------------  Packages ------------------------------ */
import * as React from "react";
// import Helmet from "react-helmet";
import styled from "styled-components";


/* -------------------------  Import pages/ components ---------------------- */
import Header from "../components/header.js"
import Carrousel from "../LandingPageComponents/carrousel.js";
import FloatingSocials from "../LandingPageComponents/floatingSocials.js";
import LandingContent from "../LandingPageComponents/landingContent.js";
import Footer from "../components/footer.js";
import { SEO } from "../components/seo.jsx";

import "./style.scss";
/* -------------------------------  Local Images ---------------------------- */
import minLogo from "../shared/A.png";

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function Dynamic({location, data}) {  
  const params = new URLSearchParams(location.search);
  const lang= params.get("lang");
    return (
      <>
        {/* <Header/> */}
        <Header data={{lang: lang}} />
        <Carrousel data={{lang:lang}} />
        <LandingContent  data={{lang:lang}} />
        <FloatingSocials data={{lang:lang}} />
        <Footer data={{lang:lang}} />
      </>
  );
}
export default Dynamic;

export const Head = () => (
  <>
      {/* <title>ATDCE</title> */}
      <link rel="icon" type="image/x-icon" href={minLogo}/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <SEO title="Home - ATDCE"/>

  </>
);
/* ---------------------------- Styled Components --------------------------- */

/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */