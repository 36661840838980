/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

/* ------------------------------- // Packages ------------------------------ */
import * as React from "react";
import styled from "styled-components";
import AboutUsLandingContent from "./aulContent";
import PartnersFundsLandingContent from "./pflContent";

import {getpageLang} from "../shared/extFunctions";
/* ----------------------------- // Import pages ---------------------------- */
import landingContent from "../shared/json/landingPage.json";
/* ----------------------------- // Local Images ---------------------------- */

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function LandingContent({data}) {
    const lang = getpageLang(data.lang);
    const content = lang==="fr"? landingContent.fr: landingContent.en;

    if (typeof document !== "undefined") {
        setTimeout(() => {
            observerSetup();
        }, 200);
    } else {
        console.warn(
            "papish famma mochkla f document/window variable",
            "LandingContent"
        );
    }
    return (
        <Container>
            <Caulc id="aulc">
                <AboutUsLandingContent data={{content: content.aulContent, lang: lang}}/>
            </Caulc>
            <Cpflc id="pflc">
                <PartnersFundsLandingContent data={{content: content.pflContent, lang: lang}}/>
            </Cpflc>
        </Container>
    );
}
export default LandingContent;
/* ---------------------------- Styled Components --------------------------- */
const Container = styled.div`
    .slideFL {
        transform: translateX(-50px);
        filter: opacity(0.1);
    }
    .slideFR {
        transform: translateX(100px);
        filter: opacity(0.1);
    }

    .inSight {
        transition: all 0.5s ease-out;
        /* background-color: #fff; */
        transform: translateX(0px);
        filter: opacity(1);
    }
`;
const Cpflc = styled.div``;
const Caulc = styled.div``;
/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */


function observerSetup() {
    const slideDivs = document.querySelectorAll(".slideFR, .slideFL");
    // console.warn("baba rahou jaw khadem",slideDivs);
    const options = {
        root: null,
        rootMargin: "0px  3000px",
    };
    const observer = new IntersectionObserver(function (entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                // console.debug(entry.target.classList[0], entry.isIntersecting);
                entry.target.classList.add("inSight");
            } else {
                // entry.target.classList.remove("inSight");
                // console.log( entry.target.classList[0], entry.isIntersecting, entry.target.classList );
            }
        });
    }, options);

    slideDivs.forEach((slideDiv) => {
        observer.observe(slideDiv);
    });
}

// if (typeof document !== `undefined`) {
//   let elemsFL = document.getElementsByClassName("slideFL");
//   var leftiesLoaded =Array.from({length: elemsFL.length}, (_, i) => false); // creates array length of elemsFL full of <false>

//   let elemsFR = document.getElementsByClassName("slideFR");
//   var rightersLoaded =Array.from({length: elemsFR.length}, (_, i) => false); // creates array length of elemsFR full of <false>

//   document.addEventListener("scroll", function (event) {

//     let windowHeight = window.outerHeight;
//       console.log("%c/* ----------- scroll ---------- */","color: purple; font-weight: bold");
//       checkIfInSight(elemsFL,leftiesLoaded,windowHeight);
//       checkIfInSight(elemsFR,rightersLoaded,windowHeight);
//   });
//   /* -------------------------------- touchmove ------------------------------- */
//   document.addEventListener("touchmove", function (event) {
//       let windowHeight = window.outerHeight;
//       console.log("%c/* ---------- touchmove --------- */","color: red; font-weight: bold");
//       checkIfInSight(elemsFL,leftiesLoaded,windowHeight);
//       checkIfInSight(elemsFR,rightersLoaded,windowHeight);
//   });
// }else {
//   console.log("is there a problem sir ?");
// }

// function checkIfInSight(elemArray,boolArray,windowHeight){
//   for (let counter=0; counter< elemArray.length; counter++) {

//       const elem=elemArray[counter];
//       let elemRect = elem.getBoundingClientRect();
//       let elemPosTop = elemRect.top;
//       let elemPosBottom = elemPosTop + elem.scrollHeight;

//       if (elemPosTop <= windowHeight && elemPosBottom >= 0) {
//           if (!boolArray[counter] ) {
//               console.log("%c In Sight And Loaded", "color: green", elem.classList[0] );
//               // console.log(elem.classList[0],"In Sight");
//               boolArray[counter] = true;
//               elem.classList.add("inSight");
//           }else {
//               // console.debug(elem.classList[0],"In Sight And Loaded");
//               console.log("%c In Sight And Loaded", "color: yellow", elem.classList[0] );
//       // console.log('red'.red);
//           }
//       } else {
//           console.log(elem.classList[0],"\tOut Of Sight", elemPosTop,"<=", windowHeight,"&&", elemPosBottom,">=0\t\t\t",elemRect.y );
//           boolArray[counter] = false;
//           elem.classList.remove("inSight");
//       }
//   }
// }
