/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

/* ---------------------------------  Packages ------------------------------ */
import * as React from "react";
// import PropTypes from "prop-types";
// import swal from "sweetalert";
// import Helmet from "react-helmet";
import styled from "styled-components";
import { Link } from "gatsby";
// import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
// import "@szhsin/react-menu/dist/index.css";

/* -------------------------------  Import pages ---------------------------- */

/* -------------------------------  Local Images ---------------------------- */
import pfl from "../images/partners.jpg";
// import { keyframes } from "styled-components";

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function PartnersFundsLandingContent({data}) {
    const {desc, image, link,pageName,title,imageblur,learnmore}= data.content;
    return (
        <Pflc>
            <div className="vRecL slideFL"></div>
            <div className="vRecR slideFR"></div>
            <div className="hRec slideFR">
                <div className="pflTitle">
                    <h1 className="discover slideFR">{title[0]}</h1>
                    <h3 className="ourPartners slideFR">{title[1]}</h3>
                </div>
            </div>
            <div className="textContainer">
                <p className="02 numbers slideFL">02.</p>
                <p className="desc slideFL">{desc}</p>
            </div>
            <Link className="imgContainer" to={link+"?lang="+data.lang}>
            {/* supposed to use {image} insteadof {pfl} but no image provided yet :( */}
                <img src={image} alt="" className="pflImg slideFL" />
                <span className="readMoreboy one">{pageName}</span>
                <span className="readMoreboy two">{learnmore}</span>
            </Link>
        </Pflc>
    );
}
export default PartnersFundsLandingContent;
/* ---------------------------- Styled Components --------------------------- */
const Pflc = styled.div`
    @import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap");
    @import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
    width: 100vw;
    height: 100vh;
    position: relative;
    margin-bottom: 20vh;

    .vRecL {
        width: 11.5vw;
        height: 115vh;
        background-color: #faf9f7;

        position: absolute;
        left: 5vw;
        bottom: -10vh;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    }
    .vRecR {
        width: 25vw;
        height: 70vh;
        background-color: #faf9f7;

        position: absolute;
        left: 40vw;
        bottom: 25vh;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .hRec {
        width: 60vw;
        height: 35vh;
        background-color: #e1d8cc;

        position: absolute;
        right: 0;
        bottom: 15vh;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
            rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        font-family: "Gothic A1", sans-serif;

        .discover {
                font-size:clamp(40px, 5.5VW,100px);
            margin: 0;
            z-index:50;
            position: relative;
            margin-left: 10%;
        }
        .ourPartners {
                font-size:clamp(20px ,3VW,50px);
            margin: 0;
            margin-left: 10%;
        }
    }

    .textContainer {
        position: absolute;
        left: 10vw;
        top: 10vh;
        z-index: 2;
        font-family: "Roboto Condensed", sans-serif;
        .numbers {
            font-size: clamp(20px,4vw,40px);
            font-weight: 700;
            width: fit-content;
            border-bottom: 3px solid #000;
            padding: 0 2ch 0 0;
            position: relative;
            &:after{
                display: block;
                width: 92.58px;
                height: 3px;
                color:red;
                position:absolute;
                bottom:0;
                left:0;
                z-index:99;
            }
        }
        .desc {
            /* font-size: 25px;
            width: 60vw; */
            font-size: clamp(15px,2.7vw, 25px);
            width: clamp(300px, 55vw, 550px);
        }
    }
    .imgContainer {
        position: absolute;
        left: 15vw;
        bottom: 0vh;
        z-index: 4;

        display: flex;
        justify-content: center;
        align-items: center;
        .pflImg {
            width: 40vw;
            /* height: 50vh;
            object-fit:cover ; */

            box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
                rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
            transition: all .5s;
            filter: brightness(1);
        }
        .readMoreboy {
            font-family: "Gothic A1", sans-serif;

            padding: 0.2vh 3vw;
            &.one {
                border-bottom: solid 2px #fff;
                font-size: clamp(19px,3vw,28px );

            }
            &.two {
                font-size: clamp(9px,1.5vw,20px);
                padding-top: 4em;
                ::after {
                    content: "→";
                }
            }

            position: absolute;
            text-decoration: none;
            color: #fff;

            opacity: 0;
            transition: opacity .5s;
        }
        :hover {
            .pflImg {
                filter: brightness(0.7);
            }
            .readMoreboy {
                opacity: 1;
            }
        }
    }
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        .vRecL{
            height: 30vh;
            width: 85vw;
            background-color: #e1d8cc;
            top: 15vh;
            left: 10vw;
            padding:0;
        }
        .vRecR{
            height: 25vh;
            width: 85vw;
            top: 17vh;
            left: 5vw;

        }
        .textContainer{
            top: 17vh;
            left: 7vw;
            /* text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center; */
            .number{

            }
            .desc{
                width: 80vw;
            }
        }
        .hRec{
            bottom: 5vh;
            height: 40vh;
            width: 85vw;
            right:0;
            background-color:#faf9f7;
            display: block;
            .pflTitle{
                text-align: right;

                position: absolute;
                right: 5vw;
                bottom: -3vh;
                & > *{
                    margin:0;
                }
                .discover{
                    
                }
            }
            &:after{
                content:"";
                display: block;
                background-color: #000;
                position: absolute; 
                top:-5vh;
                right:5vw;
                z-index: 15;
                width:1px;
                height: 80%;
            }

        }
        .imgContainer{
            bottom: 15vh;
            left: 20vw;
            
            .pflImg{
                width: auto;
                max-width: 65vw;
                object-fit:cover;
                height: 35vh;
                aspect-ratio: 1/1;
                filter:brightness(.7);
            }
            .readMoreboy{
                opacity:1;
                &.two{
                    transform: translateY(.5rem)
                }
            }
        }
    }
`;

/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */
