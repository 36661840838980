/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

/* ---------------------------------  Packages ------------------------------ */
import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import * as ReactDom from "react-dom";
/* -------------------------------  Import pages ---------------------------- */

/* -------------------------------  Local Images ---------------------------- */
import downArrow from "../images/downArrow.png";
import aul from "../images/team.jpg";
/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function AboutUsLandingContent({data}) {
    const {desc, image, link,pageName,title,imageblur,learnmore}= data.content;
    return (
        <Aulc>
            <div className="hRec slideFR"></div>
            <div className="vRec slideFR"></div>
            <div className="textContainer">
                <p className="numbers slideFL">01.</p>
                <p className="desc slideFL">
                    {desc}
                </p>
                <div className="aulTitle ">
                    {/* <h1 className="meet slideFL">RENCONTRER</h1>
                    <h3 className="ourTeam slideFL">NOTRE ÉQUIPE</h3> */}
                    <h1 className="meet slideFL">{title[0]}</h1>
                    <h3 className="ourTeam slideFL">{title[1]}</h3>
                </div>
            </div>
            <Link className="imgContainer" to={link+"?lang="+data.lang}>
            {/* supposed to use {image} insteadof {aul} but no image provided yet :( */}
                <img src={image} alt="" className="aulImg slideFR"></img> 
                <span className="readMoreboy one ">{pageName}</span>
                <span className="readMoreboy two ">{learnmore}</span>
            </Link>
            <DownArrow className="downarrow" href="#pflc">
                <img src={downArrow} alt="" />
            </DownArrow>
        </Aulc>
    );
}
export default AboutUsLandingContent;
/* ---------------------------- Styled Components --------------------------- */

const Aulc = styled.section`
    @import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap");
    @import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
    width: 100%;
    height: 100vh;
    position: relative;

    .hRec {
        background-color: #e1d8cc;
        width: 100vw;
        height: 40vh;

        position: absolute;
        bottom: -0;
        z-index: 0;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
            rgba(17, 17, 26, 0.05) 0px 8px 32px;
        @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
            /* background-color: green; */
        }
    }
    .vRec {
        background-color: #f5f2ee;
        width: 25vw;
        height: 80vh;

        position: absolute;
        bottom: 5vh;
        left: 40vw;
        z-index: 1;
        /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
            rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
    .textContainer {
        position: absolute;
        left: 10vw;
        top: 10vh;
        z-index: 8;
        font-family: "Roboto Condensed", sans-serif;

        .numbers {
            font-size: clamp(20px,4vw,40px);
            font-weight: 700;
            width: fit-content;
            border-bottom: 3px solid #000;
            padding: 0 2ch 0 0;
        }
        .desc {
            font-size: clamp(15px,2.7vw, 25px);
            width: clamp(300px, 55vw, 550px);
        }
        .aulTitle{
            margin: 0 0 0 7vw;
            
            /* color:#000; */
            .meet {
                font-size:clamp(40px, 5.5VW,100px);
                /* line-height: 90px; */
                margin: 0;
                font-family: "Gothic A1", sans-serif;
            }
            .ourTeam {
                font-size:clamp(20px ,3VW,50px);
                margin: 0;
                /* margin: 0 0 0 300px; */
                font-family: "Gothic A1", sans-serif;
            }
        }
    }
    .imgContainer{
        
        position: absolute;
        bottom: 5vh;
        left: 55vw;
        z-index: 4;

        display: flex;
        justify-content: center;
        align-items: center;
        .aulImg {
            width: clamp(200px, 40vw, 700px);
            
            box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
            box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;

            transition: all .5s;
            filter: brightness(1);
        }
        .readMoreboy{
            font-family: "Gothic A1", sans-serif;
            
            padding: .2vh 3vw;
            &.one{
                border-bottom: solid 2px #fff; 
                font-size: clamp(19px,3vw,28px );
            }
            &.two{
                font-size: clamp(9px,1.5vw,20px);
                padding-top: 4em;
                ::after{
                    content:"→";
                }
            }

            position: absolute; 
            text-decoration: none;
            color: #fff;

            opacity:0;
            transition: opacity .5s;
        }
        :hover{
            .aulImg{
                filter: brightness(.7);
            }
            .readMoreboy{
                opacity:1;
            }
        }
    }
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        .hRec{
            top:30vh;
            right: 0;
            width: 85vw;
            height: 30vh;
        }
        .vRec{
            top:28vh;
            left:20vw;
            z-index:7;
            width: 75vw;
            height: 30vh;

        }
        .textContainer{
            width: 100%;
            height: 60%; 
            border-left: 1px solid #000;
            left:5vw;
            &:after{
                content:"";
                display:block;
                height:40%;
                width: 1px;
                position: absolute;
                left:-1px;
                top: 0;
                background-color: #fff;
            }
            .numbers{    
                position: absolute;            
                top:17vh;
                left:18vw;
            }
            .desc{
                position: absolute;        
                top:25vh;
                left:18vw;    
                width: 65vw;
            }
            .aulTitle{
                position: absolute;   
                top:5vh;
                left: 0;      
                margin: 0;   
                .meet{ }
                .ourTeam{
                    width: 10ch;
                }
            }
        }
        .imgContainer{
            top: 61vh;
            left:25vw;
            z-index: 15;
            height: fit-content;
            /* box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px; */
            .aulImg{
                /* width: clamp(200px,50vw,400px); */
                width: auto;
                max-width:65vw;
                object-fit: cover;
                height:35vh;
                aspect-ratio: 1/1 ;
                /* box-shadow: 7px 8px 7px -2px rgba(0,0,0,0.31), 21px 2px 0px 7px #e1d8cc; */
                filter: brightness(.7) drop-shadow(1px 2px 7px #00000050) drop-shadow(21px 4px 0px #f5f2ee)  drop-shadow(2px 2px 7px #00000050);
            }
            .readMoreboy{
                opacity:1;
                &.two{
                    transform: translateY(.5rem)
                }
            }
        }
    }
`;

const DownArrow = styled.a`
    img {
        width: 3vw;
        min-width: 25px;
        top: 92vh;
        right: 1vw;
        position: absolute;
        transition: all 1s;
        filter: invert();
        transition: all 1s;
        z-index: 99;
        &:hover {
            /* filter: sepia(200%) saturate(150%) hue-rotate(60deg); */
            filter: none;
        }
    }
`;
/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */
